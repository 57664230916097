<template>
    <div class="container">
        <div class="section_content w-100">
            <div class="section_header m-0">
                <h2>{{ title }}<span> {{ ( type == 'opened' || type == 'clicked' ) ? count + ' %' : count }}</span> <label v-if="broadcastLogs.total">{{title == 'Recipient' ? 'Sends' : title == 'Open Rate' ? 'Opens' : title == 'Click Rate' ? 'Clicks' : title}} {{ broadcastLogs.total }}</label></h2>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul>
                        <li class="edit">
                            <label for="check" class="checkbox">
                                <input type="checkbox" id="check" hidden v-model="selectAll" @click="toggleAll">
                                <span><i class="fas fa-check"></i></span>
                            </label>
                        </li>
                        <li class="optionDrops bulk_action" @click.self="bulkActionDropdown = !bulkActionDropdown;" v-click-outside="closeBulkActionDropdown">
                            Bulk Action <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr megamenu" :class="{ active: bulkActionDropdown }">
                                <ul>
                                    <template v-for="(action, a) of bulkActions" :key="a">
                                        <li @click="triggerBulkAction(action.action)" v-if="action.hasCan && companyUserCan(action.can.action, action.can.module) && !action.hasSubMenu">
                                            {{ action.title }}
                                        </li>
                                        <li @click="triggerBulkAction(action.action)" v-if="!action.hasCan && !action.hasSubMenu">
                                            {{ action.title }}
                                        </li>
                                        <li @click="triggerSubmenu($event)" v-if="action.hasCan && companyUserCan(action.can.action, action.can.module) && action.hasSubMenu">
                                            {{ action.title }} <i class="fas fa-chevron-right"></i>
                                            <div class="sub_dropdown">
                                                <ul>
                                                    <template v-for="(submenu, s) in action.submenus" :key="s">
                                                        <li @click="triggerBulkAction(submenu.action)" v-if="submenu.hasCan && companyUserCan(submenu.can.action, submenu.can.module)">{{ submenu.title }}</li>
                                                        <li @click="triggerBulkAction(submenu.action)" v-if="!submenu.hasCan">{{ submenu.title }}</li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </li>
                        <li class="search_area">
                            <input type="text" placeholder="Search" :disabled="loader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                            <button type="button" class="search_btn">
                                <i class="fas fa-search"></i>
                            </button>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="completed-report-per-page-filter" />
                        </li>
                        <!-- <li class="list_info">
                            {{ broadcastLogs.from ? broadcastLogs.from : 0 }} - {{ broadcastLogs.to ? broadcastLogs.to : 0 }} of <span>{{ broadcastLogs.total ? broadcastLogs.total : 0 }}</span>
                        </li> -->
                    </ul>
                </div>
                <div><line-loader v-if="loader" /></div>
                <div class="scroll_table">
                    <table class="show_header">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Sent at</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="broadcastLogs.data && broadcastLogs.data.length">
                            <tr v-for="(broadcastLog, b) in broadcastLogs.data" :key="b">
                                <template v-if="broadcastLog.contact">
                                    <td>
                                        <label :for="`broadcast-log-${b}`" class="checkbox">
                                            <input type="checkbox" :id="`broadcast-log-${b}`" :value="broadcastLog.contact.id" v-model="selectedContacts" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </td>
                                    <td>
                                        <div class="user_wpr"  @click="activeContact = broadcastLog.contact; profile = true;">
                                            <div class="user_img">
                                                <img :src="broadcastLog.contact.photo" alt="">
                                            </div>
                                            <h4>{{ broadcastLog.contact.name }}</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="email_wpr">
                                            <span class="email_add">
                                                {{ broadcastLog.contact.email }}
                                            </span>
                                            <span class="text-danger" v-if="broadcastLog.contact.isDelete">[DELETED]</span>
                                        </div>
                                    </td>
                                    <td>{{ moment(broadcastLog.date_created).format('DD MMMM, YYYY | hh:mm:ss') }} (UTC)</td>
                                    <td class="action">
                                        <a @click="activeContact = broadcastLog.contact; launchPad = true;">
                                            <span v-tooltip="`Open your launchpad and perform actions`" position="left"><img src="@/assets/images/launchpad.svg"></span>
                                        </a>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="5" class="text-center" style="border-radius: 0 0 10px 10px;">
                                    <div class="empty_box">
                                        <img src="@/assets/images/empty_state.svg">
                                        <h2>No records found</h2>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="table_footer">
                <ul>
                    <li>
                        {{ broadcastLogs.from ? broadcastLogs.from : 0 }} - {{ broadcastLogs.to ? broadcastLogs.to : 0 }} of <span>{{ broadcastLogs.total ? broadcastLogs.total : 0 }}</span>
                    </li>
                </ul>
            </div>
            <div class="model_pagination pb-4 mt-4">
                <div class="pagination" v-show="broadcastLogs.total">
                    <pagination v-model="params.page" :pages="broadcastLogs.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>
    </div>
    <launch-pad v-model="launchPad" :contact="activeContact" />
    <profile-component v-model="profile" :contact="activeContact" />

    <bulk-action-change-gender v-model="genderToggle" :contacts="selectedContacts" />
    <bulk-action-switch-tab v-model="statusToggle" :contacts="selectedContacts" />
    <bulk-action-country-code v-model="togglePhoneCountryCodePanel" :contacts="selectedContacts" />
    <bulk-action-merge-contact v-model="toggleMergeContactsPanel" :contacts="selectedContacts" />
    <bulk-action-move-contact v-model="toggleAssignContactsPanel" :contacts="selectedContacts" />
    <bulk-action-celebrate-birthday v-model="toggleContactsBirthdayPanel" :contacts="selectedContacts" />
    <bulk-action-copy-contact v-model="toggleCopyContactsPanel" :contacts="selectedContacts" />
    <bulk-action-change-member-id v-model="toggleChangeMemberIdPanel" :contacts="selectedContacts" />
    <bulk-action-sms v-model="sendSMSToggle" :contacts="selectedContacts" />
    <bulk-action-add-tag v-model="addTagsToggle" :contacts="selectedContacts" />
    <bulk-action-remove-tag v-model="removeTagsToggle" :contacts="selectedContacts" />
    <bulk-action-send-email v-model="sendEmailToggle" :contacts="selectedContacts" :is-confirmation="confirmationEmail" />
    <bulk-action-start-pipeline v-model="toggleAddPipelineContactsPanel" :contacts="selectedContacts" />
    <bulk-action-remove-pipeline v-model="toggleRemovePipelineContactsPanel" :contacts="selectedContacts" />
    <bulk-action-start-sequence v-model="sendSequenceToggle" :contacts="selectedContacts" />
    <bulk-action-stop-sequence v-model="stopSequenceToggle" :contacts="selectedContacts" />
    <bulk-action-start-journey v-model="startJourneyToggle" :contacts="selectedContacts" />
    <bulk-action-terminate-journey v-model="terminateJourneyToggle" :contacts="selectedContacts" />
    <bulk-action-send-playbook v-model="assignPlaybookToggle" :contacts="selectedContacts" />
    <bulk-action-restrict-playbook v-model="restrictPlaybookToggle" :contacts="selectedContacts" />
    <bulk-action-send-template v-model="sendTemplateToggle" :contacts="selectedContacts" />
    <bulk-action-send-form v-model="sendFormToggle" :contacts="selectedContacts" />
    <bulk-action-restrict-form v-model="restrictFormToggle" :contacts="selectedContacts" />
    <bulk-action-send-page v-model="sendPageToggle" :contacts="selectedContacts" />
    <bulk-action-restrict-page v-model="restrictPageToggle" :contacts="selectedContacts" />
    <bulk-action-add-file v-model="addFileToggle" :contacts="selectedContacts" />
    <bulk-action-start-progress-tracking v-model="toggleStartProgressTracking" :contacts="selectedContacts" />
    <bulk-action-stop-progress-tracking v-model="toggleStopProgressTracking" :contacts="selectedContacts" />
    <bulk-action-start-habit-tracking v-model="toggleStartHabitTracking" :contacts="selectedContacts" />
    <bulk-action-stop-habit-tracking v-model="toggleStopHabitTracking" :contacts="selectedContacts" />
    <bulk-action-start-task v-model="toggleStartTask" :contacts="selectedContacts" />
    <bulk-action-stop-task v-model="toggleStopTask" :contacts="selectedContacts" />
    <bulk-action-start-workout v-model="toggleStartWorkout" :contacts="selectedContacts" />
    <bulk-action-stop-workout v-model="toggleStopWorkout" :contacts="selectedContacts" />
    <bulk-action-start-workout-plan v-model="toggleStartWorkoutPlan" :contacts="selectedContacts" />
    <bulk-action-stop-workout-plan v-model="toggleStopWorkoutPlan" :contacts="selectedContacts" />

</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))
    const BulkActionChangeGender = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/ChangeGender'))
    const BulkActionSwitchTab = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/SwitchTab'))
    const BulkActionCountryCode = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/CountryCode'))
    const BulkActionMergeContact = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/MergeContact'))
    const BulkActionMoveContact = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/MoveContact'))
    const BulkActionCelebrateBirthday = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/CelebrateBirthday'))
    const BulkActionCopyContact = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/CopyContact'))
    const BulkActionChangeMemberId = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/ChangeMemberId'))
    const BulkActionSms = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendSms'))
    const BulkActionAddTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddTag'))
    const BulkActionRemoveTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RemoveTag'))
    const BulkActionSendEmail = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendEmail'))
    const BulkActionStartPipeline = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartPipeline'))
    const BulkActionRemovePipeline = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RemovePipeline'))
    const BulkActionStartSequence = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartSequence'))
    const BulkActionStopSequence = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopSequence'))
    const BulkActionStartJourney = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartJourney'))
    const BulkActionTerminateJourney = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/TerminateJourney'))
    const BulkActionSendPlaybook = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPlaybook'))
    const BulkActionRestrictPlaybook = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictPlaybook'))
    const BulkActionSendTemplate = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendTemplate'))
    const BulkActionSendForm = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendForm'))
    const BulkActionRestrictForm = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictForm'))
    const BulkActionSendPage = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendPage'))
    const BulkActionRestrictPage = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/RestrictPage'))
    const BulkActionAddFile = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddFile'))
    const BulkActionStartProgressTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartProgressTracking'))
    const BulkActionStopProgressTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopProgressTracking'))
    const BulkActionStartHabitTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartHabitTracking'))
    const BulkActionStopHabitTracking = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopHabitTracking'))
    const BulkActionStartTask = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartTask'))
    const BulkActionStopTask = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopTask'))
    const BulkActionStartWorkout = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartWorkout'))
    const BulkActionStopWorkout = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopWorkout'))
    const BulkActionStartWorkoutPlan = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StartWorkoutPlan'))
    const BulkActionStopWorkoutPlan = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/StopWorkoutPlan'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import moment from 'moment'

    export default {
        data () {
            return {
                bulkActionDropdown: false,
                params: {
                    page: 1,
                    per_page: 5,
                    type: '',
                    search: '',
                    broadcast_id: '',
                },
                selectAll: false,
                selectedContacts: [],
                isTyping: false,
                activeContact: {},
                launchPad: false,
                profile: false,
                moment,
                genderToggle: false,
                statusToggle: false,
                togglePhoneCountryCodePanel: false,
                toggleMergeContactsPanel: false,
                toggleAssignContactsPanel: false,
                toggleContactsBirthdayPanel: false,
                toggleCopyContactsPanel: false,
                toggleChangeMemberIdPanel: false,
                sendSMSToggle: false,
                addTagsToggle : false,
                removeTagsToggle : false,
                sendEmailToggle : false,
                toggleAddPipelineContactsPanel : false,
                toggleRemovePipelineContactsPanel : false,
                sendSequenceToggle: false,
                stopSequenceToggle: false,
                confirmationEmail: false,
                startJourneyToggle: false,
                terminateJourneyToggle: false,
                assignPlaybookToggle: false,
                restrictPlaybookToggle: false,
                sendTemplateToggle: false,
                sendFormToggle: false,
                restrictFormToggle: false,
                sendPageToggle: false,
                restrictPageToggle: false,
                addFileToggle: false,
                toggleStartProgressTracking: false,
                toggleStopProgressTracking: false,
                toggleStartHabitTracking: false,
                toggleStopHabitTracking: false,
                toggleStartTask: false,
                toggleStopTask: false,
                toggleStartWorkout: false,
                toggleStopWorkout: false,
                toggleStartWorkoutPlan:false,
                toggleStopWorkoutPlan:false,
            }
        },

        props: {
            broadcast: Object,
            title: String,
            type: String,
            count: Number,
        },

        components: {
            LaunchPad,
            ProfileComponent,
            BulkActionChangeGender,
            BulkActionSwitchTab,
            BulkActionCountryCode,
            BulkActionMergeContact,
            BulkActionMoveContact,
            BulkActionCelebrateBirthday,
            BulkActionCopyContact,
            BulkActionChangeMemberId,
            BulkActionSms,
            BulkActionAddTag,
            BulkActionRemoveTag,
            BulkActionSendEmail,
            BulkActionStartPipeline,
            BulkActionRemovePipeline,
            BulkActionStartSequence,
            BulkActionStopSequence,
            BulkActionStartJourney,
            BulkActionTerminateJourney,
            BulkActionSendPlaybook,
            BulkActionRestrictPlaybook,
            BulkActionSendTemplate,
            BulkActionSendForm,
            BulkActionRestrictForm,
            BulkActionSendPage,
            BulkActionRestrictPage,
            BulkActionStartProgressTracking,
            BulkActionStopProgressTracking,
            BulkActionAddFile,
            BulkActionStartHabitTracking,
            BulkActionStopHabitTracking,
            BulkActionStartTask,
            BulkActionStopTask,
            BulkActionStartWorkout,
            BulkActionStopWorkout,
            BulkActionStartWorkoutPlan,
            BulkActionStopWorkoutPlan,
        },

        watch: {
            type (val) {
                if (val != 'content') {
                    const vm = this;

                    vm.params = {
                                    page: 1,
                                    per_page: 5,
                                    type: val,
                                    search: '',
                                    broadcast_id: vm.broadcast.id,
                                };

                    vm.getBroadcastLogs(vm.params);
                }
            },

            'params.per_page' (val) {
                const vm = this;

                vm.params.per_page = val;

                vm.getBroadcastLogs(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getBroadcastLogs(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getBroadcastLogs(vm.params);
                        }
                    }
                }
            },

            bulkActionWatcher (val) {
                const vm = this;

                vm.selectAll        = false;
                vm.selectedContacts = [];
            }
        },

        computed: {
            ...mapState({
                bulkActions: state => state.contactModule.bulkActions,
                loader: state => state.broadcastModule.broadcastLogsLoader,
                broadcastLogs: state => state.broadcastModule.broadcastLogs,
                bulkActionWatcher: state => state.contactModule.bulkActionWatcher,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            })
        },

        methods: {
            ...mapActions({
                getBroadcastLogs: 'broadcastModule/getBroadcastLogs',
                bulkDelete: 'contactModule/bulkDelete',
                enableDisableDeliveryStatus: 'contactModule/enableDisableDeliveryStatus',
                disableConfirmation: 'contactModule/disableConfirmation',
                validateEmails: 'contactModule/validateEmails',
                markContactAsStarred: 'contactModule/markContactAsStarred',
                updateTemperature: 'contactModule/updateTemperature',
                getCustomFields: 'contactModule/getCustomFields',
            }),

            ...mapMutations({
                resetTimezoneByCountry: 'commonModule/SET_TIMEZONES_BY_COUNTRY',
            }),

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['completed-report-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeBulkActionDropdown () {
                const vm = this;
                const allParentElement  = document.querySelectorAll('.bulk_action .megamenu ul li');
                vm.bulkActionDropdown = false;

                for(let i = 0; i < allParentElement.length; i++){
                    allParentElement[i].classList.remove('show');
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getBroadcastLogs(vm.params);
            },

            togglePerPageFilter () {
                const vm = this;
                vm.bulkActionDropdown = false;

                const filter = vm.$refs['completed-report-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            toggleAll () {
                const vm = this;

                if (!vm.selectAll) {
                    vm.selectedContacts = vm.filterIndexValue(vm.broadcastLogs.data);
                } else {
                    vm.selectedContacts = [];
                }
            },

            filterIndexValue (object, index = 'id') {
                const records = [];

                object.forEach((obj) => { records.push(obj['contact'][index]) });

                return records;
            },

            triggerBulkAction (action) {
                const vm = this;

                if (vm.selectedContacts.length) {
                    if (action == 'deleteContactsPrompt') {
                        const options = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover these contacts', 'Delete')
                                  options.preConfirm = function() { return vm.bulkDelete({ contacts: vm.selectedContacts , deleted_through: 'Broadcasts' }); };

                        Swal.fire(options);
                    } else if(action == 'toggleMergeContactsPanel') {
                        if( vm.selectedContacts.length < 2 || vm.selectedContacts.length > 3) {
                            const options = Helper.swalWarningOptions('Oops!', 'Please select a minimum of 2 or maximum of 3 contacts');

                            Swal.fire(options)
                        } else {
                            vm[action] = true;
                        }
                    } else if (action == 'toggleDeactivateBirthdayPanel') {
                        const options = Helper.swalConfirmOptions('', "You wish to deactivate these birthday celebration.")
                        options.preConfirm = function () {
                            const params = { status: 0, contact_id: vm.selectedContacts.join(','), type: 'birthday' };
                            return vm.enableDisableDeliveryStatus(params).then((result) => {
                                if (result) {
                                    vm.selectAll = false;
                                    vm.selectedContacts = [];
                                }
                            });
                        };

                        Swal.fire(options);
                    } else if (action == 'disableSMSSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the SMS sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('sms', 0) };

                        Swal.fire(options);
                    } else if (action == 'enableSMSSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to enable the SMS sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('sms', 1) };

                        Swal.fire(options);
                    } else if (action == 'disableEmailSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the E-mail sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('email', 0) };

                        Swal.fire(options);
                    } else if (action == 'enableEmailSendingPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to enable the E-mail sending for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('email', 1) };

                        Swal.fire(options);
                    } else if (action == 'disableConfirmationPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable the E-mail confirmation for the selected contacts.")
                        options.preConfirm = function() { return vm.disableConfirmation({ contacts: vm.selectedContacts }) };

                        Swal.fire(options);
                    } else if (action == 'requestConfirmationToggle') {
                        vm.confirmationEmail  = true;
                        vm.sendEmailToggle    = true;
                    } else if (action == 'validateEmailPrompt') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to Validate E-mail for the selected contacts.")
                        options.preConfirm = function() { return vm.validateEmails({ contacts: vm.selectedContacts }) };

                        Swal.fire(options);
                    } else if (action == 'enableBroadcasts') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to enable broadcast for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('broadcast', 1) };

                        Swal.fire(options);
                    } else if (action == 'disableBroadcasts') {
                        const options = Helper.swalConfirmOptions('Are You Sure?', "You want to disable broadcast for the selected contacts.")
                        options.preConfirm = function() { return vm.handleDelivaryStatus('broadcast', 0) };

                        Swal.fire(options);
                    } else {
                        vm[action] = true;
                    }
                } else {
                    Toastr.error('Please select at least one contact to use bulk action!');
                }
            },

            handleDelivaryStatus (type, status) {
                const vm = this;

                const params = {
                    contacts: vm.selectedContacts,
                    type: type,
                    status: status,
                }

                return vm.enableDisableDeliveryStatus(params).then((result) => {
                    vm.selectAll = false;
                    vm.selectedContacts = [];
                });
            },

            triggerSubmenu (ev) {
                const parentElement = ev.currentTarget;
                const allParentElement  = document.querySelectorAll('.bulk_action .megamenu ul li');

                if (parentElement.classList.contains('show')) {
                    parentElement.classList.remove('show');
                } else {
                    for(let i = 0; i < allParentElement.length; i++){
                        allParentElement[i].classList.remove('show');
                    }

                    parentElement.classList.add('show');
                }
            },
        }
    }
</script>

<style scoped>
    .container {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
    }
    .section_header h2 {
        display: flex;
        align-items: center;
    }

    .section_header h2 span {
        padding: 3px 8px;
        background: #f2a31d;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #fff;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        margin: 0 10px 0 10px;
    }
    .section_header h2 label{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 0 10px;
        border-left: 1px solid #cdcdcd;
    }

    :deep(.setting_wpr form > .action_wpr) {
        position: absolute !important;
    }

    :deep(.modal_container .modal_body .setting_wpr > form) {
        margin-bottom: 75px;
    }
    .result_wpr .actions > ul{
        width: 100%;
    }
    .result_wpr .actions > ul li.search_area{
        margin-left: auto;
    }
    .result_wpr table{
        min-width: 800px;
    }
    @media(max-width: 767px){
        .result_wpr .actions > ul li.search_area input[type=text] {
            width: 200px;
        }
        .dropdown_wpr.megamenu {
            left: 0;
        }
    }
    @media(max-width: 550px){
        .result_wpr .actions > ul li.search_area{
            width: 100%;
            order: 1;
        }
        .result_wpr .actions > ul li.sort_list{
            margin-left: auto;
        }
    }
</style>

